import React from 'react'
import { graphql } from 'gatsby'
import styles from '../assets/scss/templates/PostTemplate.module.scss'

import Layout from '../components/layout'
import Banner from './banner'
import Author from './author'
import Sidenav from './sidenav'

// import SEO from "../utils/SEO";

export default function Template({
    data, // this prop will be injected by the GraphQL query below.
}) {
    const { markdownRemark } = data // data.markdownRemark holds our post data
    const { frontmatter, html } = markdownRemark

    return (
        <Layout>
            <div>
                {/* <SEO post={markdownRemark} isPost={true}/> */}
                <Banner
                    title={frontmatter.title}
                    subtitle={frontmatter.subtitle}
                    date={frontmatter.date}
                    image={frontmatter.image.childImageSharp.sizes.base64}
                />
                <div className={styles.PostTemplate}>
                    <div>
                        <div className={styles.Content}>
                            <div dangerouslySetInnerHTML={{ __html: html }} />
                            <Author author={frontmatter.author} />
                        </div>
                        <Sidenav
                            categories={data.allMarkdownRemark.categories}
                            author={frontmatter.author}
                            posts={data.recentPosts.edges}
                            tags={data.allMarkdownRemark.tags}
                        />
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export const pageQuery = graphql`
    query BlogPostByPath($path: String!) {
        recentPosts: allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___date] }
            limit: 10
        ) {
            edges {
                node {
                    frontmatter {
                        path
                        title
                    }
                }
            }
        }
        allMarkdownRemark(limit: 2000) {
            categories: group(field: frontmatter___category) {
                fieldValue
                totalCount
            }
            tags: group(field: frontmatter___tags) {
                fieldValue
                totalCount
            }
        }
        markdownRemark(frontmatter: { path: { eq: $path } }) {
            html
            excerpt(pruneLength: 250)
            frontmatter {
                date(formatString: "MMMM DD, YYYY")
                path
                title
                subtitle
                author
                category
                image {
                    childImageSharp {
                        sizes(maxWidth: 1200, quality: 80) {
                            base64
                            aspectRatio
                            src
                            srcSet
                            sizes
                            originalImg
                        }
                    }
                }
            }
        }
    }
`
