import React from 'react'

import { team } from '../utils/team-info'

const Author = props => {
    const author = team[props.author]
    return (
        <div className="author-info">
            <a target="_blank" rel="noopener noreferrer" href={author.linkdin}>
                <img src={author.img} alt={author.name} />
            </a>

            <div>
                <h5>{author.name}</h5>
                <p>{author.shortbio}</p>
            </div>
        </div>
    )
}

export default Author
